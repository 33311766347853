<template>
  <v-card class="wx-panel wx-card-container">
    <img class="logo-img rounded-0 mb-4" height="40px" :src="logoSrc" :alt="$t('mainNav.logoAltText')" />
    <h1 class="wx-typo-h2 mb-2">
      {{ $t("homepage.title") }}
    </h1>
    <p>
      {{ $t("homepage.intro") }}
    </p>
    <v-row class="w-100 mx-0">
      <v-col cols="12" class="d-flex justify-end px-0">
        <wx-btn-standard @click="signIn" color="primary" class="mb-2">
          {{ $t("homepage.login") }}
          <v-icon right dense>mdi-chevron-right</v-icon>
        </wx-btn-standard>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import WxBtnStandard from "@/components/ui/WxBtnStandard";
import * as authRedirect from "../authentication/authRedirect";
import { getLanguageFromLocalStorageOrDefault } from "@/i18n";
import { isThemeDarkFromLocalStorage } from "@/components/Theme";

export default {
  name: "LoginPanel",
  components: {
    WxBtnStandard,
  },
  computed: {
    logoSrc() {
      return this.$vuetify.theme.light
        ? require("@/assets/tile-plus-logo-light-horizontal.svg")
        : require("@/assets/tile-plus-logo-dark-horizontal.svg");
    },
  },
  methods: {
    signIn() {
      return authRedirect.signIn();
    },
  },
  mounted() {
    this.$i18n.locale = getLanguageFromLocalStorageOrDefault();
    this.$vuetify.theme.dark = isThemeDarkFromLocalStorage();
  },
};
</script>
