<template>
  <login-panel />
</template>
<script>
import LoginPanel from "@/components/LoginPanel";

export default {
  components: {
    LoginPanel,
  },
};
</script>
